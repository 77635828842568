<template>
  <div>
    <el-tooltip placement="top" class="d-inline-flex gap-1 w-100" :open-delay="500">
      <template #content>
        <template v-if="unbilledOrders && unbilledOrders.length">
          <p>* {{ $t('payment.paymentTable.tooltips.impermanentTotalBilledAmount') }}</p>
          <p v-if="unbilledOrders.length > 1">
            {{
              isNaN(totalUnbilledOrders)
                ? $t('payment.paymentTable.tooltips.totalUnbilledOrdersUnknown', {
                    ordersCount: unbilledOrders.length,
                  })
                : $t('payment.paymentTable.tooltips.totalUnbilledOrders', {
                    ordersCount: unbilledOrders.length,
                    amount: formatMoney(totalUnbilledOrders),
                  })
            }}
          </p>
          <p v-if="unbilledOrders.length == 1">
            {{
              isNaN(totalUnbilledOrders)
                ? $t('payment.paymentTable.tooltips.singleUnbilledOrderUnknown')
                : $t('payment.paymentTable.tooltips.singleUnbilledOrder', {
                    amount: formatMoney(totalUnbilledOrders),
                  })
            }}
          </p>
        </template>
        <template v-else>
          <p v-if="!totalPayment">
            {{
              $t('operationManagement.ongoingOperations.table.tooltips.totalPayment.fullAmount', {
                totalAmount: formatMoney(0),
              })
            }}
          </p>
          <p v-else-if="!totalPayment.totalPaidAmount">
            {{
              $t('operationManagement.ongoingOperations.table.tooltips.totalPayment.fullAmount', {
                totalAmount: formatMoney(totalPayment.totalBilledAmount),
              })
            }}
          </p>
          <p v-else>
            {{
              $t('operationManagement.ongoingOperations.table.tooltips.totalPayment.partialAmount', {
                leftToPay: formatMoney(totalPayment.totalBilledAmount - totalPayment.totalPaidAmount),
                totalAmount: formatMoney(totalPayment.totalBilledAmount),
              })
            }}
          </p>
        </template>
      </template>
      <div class="w-100">
        <div style="width: 98%" class="d-flex">
          <span v-if="unbilledOrders && unbilledOrders.length">*</span>
          <span v-if="!totalPayment">{{ formatMoney(0) }}</span>
          <Button
            v-else
            type="link"
            class="text-typography-primary p-0 w-100 text-start"
            @click.stop="setPaymentInvoicesModalPayment"
          >
            <p v-if="!totalPayment.totalPaidAmount">
              {{ formatMoney(totalPayment.totalBilledAmount) }}
            </p>
            <div v-else-if="truncated">
              <TruncatedText>{{ leftToPay }}</TruncatedText>
            </div>
            <div v-else>{{ leftToPay }}</div>
          </Button>
        </div>
      </div>
    </el-tooltip>
    <PaymentInvoicesModal
      v-if="paymentInvoicesModalPayment"
      :payment="paymentInvoicesModalPayment"
      @close="paymentInvoicesModalPayment = null"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { isNil } from 'ramda';

import { Button, TruncatedText } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  components: {
    Button,
    TruncatedText,
    PaymentInvoicesModal: () => import('@/modules/payment/customer/components/PaymentInvoicesModal'),
  },
  props: {
    billedAmounts: { type: Array, default: () => [] },
    unbilledOrders: { type: Array, default: () => [] },
    supplier: { type: Object, required: true },
    customer: { type: Object, required: true },
    billingIds: { type: Array, default: () => [] },
    totalBilledAmount: { type: Number, default: null },
    totalPaidAmount: { type: Number, default: null },
    truncated: { type: Boolean, default: false },
  },
  setup(props) {
    const { formatToCurrency } = useCurrency();

    const totalPayment = computed(() =>
      props.billedAmounts.length
        ? { totalBilledAmount: props.totalBilledAmount, totalPaidAmount: props.totalPaidAmount }
        : null
    );
    const totalUnbilledOrders = computed(() =>
      props.unbilledOrders.length
        ? props.unbilledOrders.reduce((acc, { netAmount }) => (!isNil(netAmount) ? acc + netAmount : NaN), 0)
        : null
    );
    return {
      paymentInvoicesModalPayment: ref(null),
      totalPayment,
      totalUnbilledOrders,
      formatToCurrency,
    };
  },
  computed: {
    leftToPay() {
      return this.$t('operationManagement.ongoingOperations.table.billingCountField', {
        leftToPay: this.formatMoney(this.totalPayment.totalBilledAmount - this.totalPayment.totalPaidAmount) ?? '-',
        total: this.formatMoney(this.totalPayment.totalBilledAmount) ?? '-',
      });
    },
  },
  methods: {
    formatMoney(value) {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value);
        const currencyOptions = Number.isInteger(value) ? { maximumFractionDigits: 0 } : {};
        return this.formatToCurrency(number, currencyOptions);
      }
      return '-';
    },
    setPaymentInvoicesModalPayment() {
      this.paymentInvoicesModalPayment = {
        billingIds: this.billingIds,
        supplier: this.supplier,
        customer: this.customer,
        totalPayment: this.billedAmounts.length
          ? { totalBilledAmount: this.totalBilledAmount, totalPaidAmount: this.totalPaidAmount }
          : null,
      };
    },
  },
};
</script>
