import { render, staticRenderFns } from "./StatusReflectionCard.vue?vue&type=template&id=feb4a2d2&scoped=true"
import script from "./StatusReflectionCard.vue?vue&type=script&lang=js"
export * from "./StatusReflectionCard.vue?vue&type=script&lang=js"
import style0 from "./StatusReflectionCard.vue?vue&type=style&index=0&id=feb4a2d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feb4a2d2",
  null
  
)

export default component.exports