<template>
  <div v-capture-scroll="onScroll" class="card status-reflection-card">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h4>{{ translate('reconciliationStatusReflection') }}</h4>
      <div class="d-flex justify-content-between align-items-center gap-2">
        <PerformedActionsModal :inquiries="inquiries" @close="performedActionsButtonActive = false">
          <template #button>
            <Button
              slot="reference"
              type="link"
              class="text-typography-primary p-0"
              :class="{
                disabled: !inquiries.length,
                active: performedActionsButtonActive,
              }"
              @click="performedActionsButtonActive = !performedActionsButtonActive"
              >{{ $t('reconciliation.statusReconciliationCard.actionsPerformed') }}<ChevronIcon />
            </Button>
          </template>
        </PerformedActionsModal>
        <TasksDetail
          v-if="hasReconciliationManage"
          :tasks="openTasks"
          :supplier-name="supplier.name"
          :customer-name="customerName"
          @update-task="$emit('update-task', $event)"
          @close="tasksDetailsButtonActive = false"
        >
          <template v-if="isAdmin && openTasks.length" #button>
            <Button
              slot="reference"
              type="link"
              class="text-typography-primary p-0"
              :class="{ active: tasksDetailsButtonActive }"
              @click="tasksDetailsButtonActive = !tasksDetailsButtonActive"
              >{{ $tc('billing.billingManagement.billingTable.tasksNumber', openTasks.length) }}<ChevronIcon />
            </Button>
          </template>
        </TasksDetail>
      </div>
    </div>
    <div class="d-flex mb-2">
      <p class="text-typography-secondary" style="width: 72px; min-width: 72px">{{ translate('status') }}</p>
      <ReconciliationStatus
        class="me-1"
        :is-admin="isAdmin"
        :reconciliation-id="reconciliationId"
        :reconciliation-status="reconciliationStatus"
        :status-override="statusOverride"
        :business-id="businessId"
        :supplier="supplier"
        :show="overrideStatusModal"
        :show-override-indication="true"
        @open="overrideStatusModal = true"
        @close="overrideStatusModal = false"
      />
    </div>
    <div class="d-flex mb-2 reason">
      <p class="text-typography-secondary" style="width: 72px; min-width: 72px">{{ translate('reason') }}</p>
      <p v-if="reconciliationStatus" class="truncated-text" :class="{ expandable }">
        {{ reason }}
      </p>
      <p v-if="expandable" style="height: 63px"></p>
    </div>
    <div class="d-flex">
      <div style="width: 72px"></div>
      <small v-if="reconciliationStatus" class="text-typography-secondary">
        {{ updatedBy }}
      </small>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { complement, either, isNil, isEmpty, flatten } from 'ramda';

import { Button } from '@/modules/core';
import { ChevronIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { useGlobalPermissions } from '@/modules/permissions';
import { REQUESTS_QUERY, useDeleteInquiry } from '@/modules/requests';
import { captureScroll } from '@/directives/capture-scroll';

import { RECONCILIATION_STATUSES } from '..';

export default {
  components: {
    Button,
    ChevronIcon,
    ReconciliationStatus: () => import('@/modules/reconciliation/reconciliationStatus/ReconciliationStatus'),
    TasksDetail: () => import('@/modules/tasks/components/TasksDetail'),
    PerformedActionsModal: () => import('@/modules/reconciliation/components/PerformedActionsModal'),
  },
  directives: {
    captureScroll,
  },
  props: {
    statusReflection: { type: Object, default: () => {} },
    supplier: { type: Object, default: null },
    businessId: { type: String, default: null },
    reconciliation: { type: Object, default: () => null },
    reconciliationId: { type: String, default: null },
    isAdmin: { type: Boolean, default: () => false },
    tasks: { type: Array, default: () => [] },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();

    const taskIds = computed(() => props.tasks.map(({ id }) => id));
    const { hasReconciliationManage } = useGlobalPermissions();
    const requests = ref([]);

    watch(
      taskIds,
      async (newTaskIds) => {
        requests.value = flatten(
          await Promise.all(
            newTaskIds.map((taskId) => {
              return root.$apollo
                .query({ query: REQUESTS_QUERY, variables: { taskId } })
                .then(({ data: { requests } }) => requests.nodes);
            })
          )
        );
      },
      { immediate: true }
    );

    const { onDone } = useDeleteInquiry();
    onDone(({ data: { requestInquiryDelete } }) => {
      requests.value = requests.value.map((request) => ({
        ...request,
        inquiries: request.inquiries?.filter(({ id }) => id !== requestInquiryDelete),
      }));
    });

    return {
      customerName: computed(() => currentTenant.value.name),
      overrideStatusModal: ref(false),
      expandable: ref(false),
      performedActionsButtonActive: ref(false),
      tasksDetailsButtonActive: ref(false),
      openTasks: computed(() => props.tasks.filter((task) => !task.completedAt)),
      requests,
      hasReconciliationManage,
    };
  },
  computed: {
    reconciliationStatus() {
      return this.reconciliation?.status;
    },
    statusOverride() {
      return this.reconciliation?.statusOverride;
    },
    hasStatusReflectionReason() {
      return complement(either(isNil, isEmpty))(this.statusReflection) && !!this.statusReflection.reason;
    },
    reason() {
      if (!this.reconciliationStatus) return '';
      const { status } = this.reconciliationStatus;
      const override = this.statusOverride;
      if (override && (override.status === RECONCILIATION_STATUSES.APPROVED || !this.hasStatusReflectionReason)) {
        return this.getDefaultReason(override.status);
      }
      if (this.beforeReconciliationClose && !this.hasStatusReflectionReason)
        return this.getDefaultReason('notCompleted');
      if (status && (status === RECONCILIATION_STATUSES.APPROVED || !this.hasStatusReflectionReason)) {
        return this.getDefaultReason(status);
      }
      return this.statusReflection.reason;
    },
    updatedBy() {
      const { status } = this.reconciliationStatus;
      const override = this.statusOverride;
      return !this.hasStatusReflectionReason ||
        override?.status === RECONCILIATION_STATUSES.APPROVED ||
        status === RECONCILIATION_STATUSES.APPROVED
        ? this.translate('updatedByClarity')
        : this.translate('updatedBy', {
            user:
              this.statusReflection.updatedBy?.firstName + ' ' + this.statusReflection.updatedBy?.lastName?.charAt(0),
            date: this.formatDate(this.statusReflection.updatedAt),
          });
    },
    reconciliationMonth() {
      return DateTime.fromISO(this.reconciliation.periodStart).toFormat('yyyy-LL');
    },
    reconciliationEndDate() {
      return DateTime.fromISO(this.reconciliation.periodEnd).plus({ month: 1 }).startOf('month');
    },
    beforeReconciliationClose() {
      return DateTime.fromJSDate(new Date()) < this.reconciliationEndDate;
    },
    inquiries() {
      const inquiries = flatten(
        this.requests.map(({ id, inquiries }) => (inquiries ?? []).map((inquiry) => ({ ...inquiry, requestId: id })))
      );
      const hasPerformedActions = complement(either(isNil, isEmpty))(this.reconciliation.actionsPerformed) ?? null;
      if (hasPerformedActions)
        inquiries.push({
          reconciliationId: this.reconciliation.id,
          content: this.reconciliation.actionsPerformed?.split('\n'),
        });

      return inquiries;
    },
  },
  watch: {
    reason() {
      this.$nextTick(() => {
        this.expandable = false;
        this.$nextTick(() => {
          const height = this.reason ? document.querySelector('.reason')?.clientHeight : 0;
          this.expandable = height >= 63;
        });
      });
    },
  },
  methods: {
    onScroll() {
      this.overrideStatusModal = false;
    },
    getDefaultReason(status) {
      switch (status) {
        case RECONCILIATION_STATUSES.APPROVED:
          return this.translate('defaultReasonApproved');
        case RECONCILIATION_STATUSES.PENDING:
          return this.translate('defaultReasonPending');
        case RECONCILIATION_STATUSES.NOT_APPROVED:
          return this.translate('defaultReasonNotApproved');
        case 'notCompleted':
          return this.translate('defaultReasonRecoPeriodNotCompleted', {
            reconciliationEndDate: this.formatDate(this.reconciliationEndDate),
            currentReconciliationMonth: this.formatDateShort(this.reconciliationMonth),
          });
        default:
          return;
      }
    },
    translate(key, obj) {
      return this.$t(`reconciliation.statusReconciliationCard.${key}`, obj);
    },
    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, { day: 'numeric', month: 'numeric', year: '2-digit' })
        : '-';
    },
    formatDateShort(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            month: 'short',
            year: 'numeric',
          })
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.status-reflection-card {
  padding: 20px;
}

.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* limit to three lines */
  line-clamp: 3; /* fallback for other browsers */
  word-break: break-word;
}

.expandable:hover {
  -webkit-line-clamp: unset;
  line-clamp: unset; /* show all lines on hover */
  display: inline-block;
  padding: 0.5rem;
  background: #fff;
  position: absolute;
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1;
}

:deep(button).active {
  text-decoration: underline;
}

.disabled {
  color: $typography-secondary !important;
  text-decoration: unset !important;
  cursor: not-allowed;
}
</style>
